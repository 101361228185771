.banerbg {
    position: relative;
    width: 100%;
    height: 54vh;
    overflow: hidden;
    background-image: url('../../assets/video/mar-caratula.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    color: #ffffff;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content h1 {
    color: #28313f;
    font-weight: bold;
    font-size: 3rem;
}

.content h2 {
    color: #28313f;
    font-size: 3rem;
}

@media screen and (max-width:768px){
    .content h1 {
        font-size: 2rem;
    }
    
    .content h2 {
        font-size: 2rem;
    }
}