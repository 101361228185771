.boxImg {
    width: 100%;
}

p{
    color: white;
}

h4{
    color: white;
    font-size: 1.5rem;
}

.btn-linea{
    text-decoration: none;
}

.btn-linea:hover {
    text-decoration: underline;
}