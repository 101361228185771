.fondo{
    background-color: white;
    overflow: hidden;
}

.subTitulo{
    color: #8896a3;
}

.colorTitulo{
    color: #38afd9;
}

.iconoMaquina{
    position: absolute;
    bottom: 0;
    left: 0;
}

.personas{
    position: absolute;
    bottom: 0;
    right: -150px;
    width: 130%;
}

.contenidoPersonas{
    height: 248px;
}

@media screen and (max-width: 1600px) {
    .personas{
        width: 120%;
    }
}

@media screen and (max-width: 1199px) {
    .personas{
        width: 110%;     
    }

    .contenidoPersonas{
        height: 272px;
    }
}

@media screen and (max-width: 991px) {
    .contenidoPersonas{
        height: auto;
    }

    .personas{
        position: initial;
    }

    .iconoMaquina{
        position: initial;
    }
}

@media screen and (max-width: 900px) {
    .personas{
        width: 100%;
    }
}