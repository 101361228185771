.boxImg {
    width: 100%;
}

.TextBlack{
    color: #687487;
}

@media screen and (max-width:580px){
    .MiniTitulo a{
        font-size: .9rem;
    }
    .MiniTitulo h3{
        font-size: 1rem;
    }
}