.navbar {
    background-color: #2c3e50 !important;
    padding: 10px;
  }
  
  .navbar .dropdown-menu {
    background-color: #bdc3c7;
    border: none;
    border-radius: 5px;
  }
  
  .navbar .dropdown-item {
    color: #2c3e50 !important;
    font-size: 14px;
    text-decoration: none;
    padding: 10px 20px;
  }
  
  .navbar .dropdown-item:hover {
    background-color: #34495e !important;
    color: #ffffff !important;
  }
  
  .navbar .nav-link {
    color: #ecf0f1 !important;
    font-size: 16px;
    font-weight: bold;

  }
  
  .navbar .nav-link:hover {
    color: #1abc9c !important;
  }

  .navbar .dropdown-toggle::after {
    display: none !important;
  }

  .navbar .dropdown-menu {
    border-radius: 0 !important;
  }

  .navbar .nav-link {
    font-family: 'RobotoLigth', sans-serif !important;
    font-size: 20px;
  }

  @media screen and (max-width : 576px){ 
    .botonDrop a{

      font-size: 12px !important;
      padding: 10px 15px 10px 0;
    }
  }