.Banimg{
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vw;
    padding-left: 12px;
}
.BannerBarredora{
    background-image: url(../../../../assets/img/bannersFregadoraYBarredora/banner-Fregadoras-Barredoras-Viper.jpg);
}

.Fregadora{
    width: 430px;
}

.textcolor{
    color: #959FB0;
}

.tituloh1{
    font-size: 4rem;
}

@media screen and (max-width:768px) {
    
    .tituloh1{
        font-size: 1.5rem;
    }

    .Fregadora{
        width: 238px;
    }
}

@media screen and (max-width:578px) {
    .tituloh1{
        font-size: 1.1rem;
    }
}