.Boximg{
    width: 100%;
}

.textoLeft{
    text-align: left;
}
.TextBlack{
    color: #687487;
}

.TextGris{
    color: #B7BDC6;
}
.TextCeleste{
    color: #39afd9;
}

.Fregadora2{
    width: 238px;
}

.media-thumbnails {
    display: flex;
    justify-content: center;
    padding-top: 4px;
}
  
.media-thumbnail {
    display: inline-block;
    margin-right: 10px; /* Espacio entre las imágenes, ajusta según tus necesidades */
}

.media-thumbnail.active .thumbnail-square {
    outline: 5px solid #baddf6;
    border-radius: 3px;
}
  
.media-thumbnail:last-child {
    margin-right: 0; /* Elimina el margen derecho del último elemento para evitar espacios extra */
}

.media-thumbnail img {
    width: 140px;
    height: auto; /* Para mantener la proporción de aspecto */
}

.media-thumbnail:hover {
    box-shadow: 0 5px 10px 3px rgba(206, 215, 236, 0.6);
}

.thumbnail-square {
    width: 100px; /* ajusta el tamaño del cuadrado según tus necesidades */
    height: 100px; /* ajusta el tamaño del cuadrado según tus necesidades */
    overflow: hidden;
}

.thumbnail-square img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BotonDescripcion{
    font-size: 0.9rem;
    margin-top: -5px;
}

.list-group-item:hover {
    background-color: #fafafa;
}

@media screen and (max-width: 575px) {
    .thumbnail-square {
        width: 75px;
        height: 75px;;
    }
}